<template>
    <div class="login-main">
        <div class="login-head">
            <div class="login-head-title">{{ $t("账号绑定") }}</div>
            <!-- <div class="login-head-brief">{{$t('欢迎再次回来')}} ~</div> -->
        </div>
        <div class="login-body">
            <div class="login-right taglibs">
                <van-checkbox-group class="van-checkbox-group--horizontal" v-model="typeSel">
                    <van-checkbox class="van-checkbox--horizontal" shape="square" name="1">{{ $t("采购") }}</van-checkbox>
                    <van-checkbox class="van-checkbox--horizontal" shape="square" name="2">{{ $t("销售") }}</van-checkbox>
                </van-checkbox-group>
            </div>

            <div class="login-right">
                <van-field
                        class="login-input"
                        style="width: 100%"
                        v-if= "!smsForm.company"
                        v-model="smsForm.company"
                        :placeholder="$t('company_en')"
                >
                    <template #left-icon>
                        <img class="login-icon" src="../assets/img/icon-user.png"/>
                    </template>
                </van-field>
                <van-field
                        class="login-input"
                        style="width: 100%"
                        v-model="smsForm.contact"
                        :placeholder="$t('Contact')"
                >
                    <template #left-icon>
                        <img class="login-icon" src="../assets/img/icon-user.png"/>
                    </template>
                </van-field>
                <van-field
                        class="login-input"
                        style="width: 100%"
                        v-model="smsForm.email"
                        :placeholder="$t('email')"
                >
                    <template #left-icon>
                        <img class="login-icon" src="../assets/img/icon-email.png"/>
                    </template>
                </van-field>
                <van-field
                        class="login-input"
                        v-model="smsForm.mobile"
                        :placeholder="$t('contact_tel')"
                >
                    <template #left-icon>
                        <img class="login-icon" src="../assets/img/icon-mobile.png"/>
                    </template>
                </van-field>
                <van-field
                        class="login-input"
                        v-model="areaName"
                        :placeholder="$t('国家区号')"
                        @focus="showArea"
                        @blur="hideArea"
                >
                    <template #right-icon>
                        <van-icon name="close" @click.stop="clearPicker"/>
                        <div v-if="areaShow" class="picker-main">
                            <div
                                    v-for="(item, index) in areaList"
                                    :key="'area' + index"
                                    class="picker-list"
                                    @click="selectPicker(index)"
                            >
                                {{ item.name }}
                            </div>
                        </div>
                    </template>
                </van-field>
                <van-field
                        class="login-input"
                        style="width: 100%"
                        v-model="smsForm.msg_code"
                        :placeholder="$t('短信验证码')"
                >
                    <template #left-icon>
                        <img class="login-icon" src="../assets/img/icon-password.png"/>
                    </template>
                    <template #right-icon>
                        <van-button round block :disabled="countFlag" type="button" class="login-code-btn"
                                    @click="sendMsg">{{ !countFlag?$t("发送短信"):countNum+"S" }}
                        </van-button>
                    </template>
                </van-field>
            </div>
            <div class="login-right taglibs">
                <van-checkbox-group class="van-checkbox-group--horizontal " v-model="tagSel">
                    <van-checkbox class="van-checkbox--horizontal taglibs-item" shape="square"
                                  v-for="(item,i) in taglibList" :key="i"
                                  :name="item.id" >{{item.name}}
                    </van-checkbox>
                </van-checkbox-group>
            </div>
            <div class="login-right ">
                <van-button round block type="info"
                            class="login-btn"
                            @click="bindInfo"
                >{{ $t("提交") }}
                </van-button>
            </div>
        </div>
    </div>
</template>
<script>
    import {Toast} from "vant";

    export default {
        name: "Login",
        data() {
            return {
                typeSel: ['1'],
                tagSel: [],
                taglibList: [],
                areaShow: false, // 区号选择框是否显示
                areaArr: [], // 总区号列表
                areaList: [], // 筛选后显示的列表
                areaIndex: -1, // 选中的总区号列表索引     提交的id值示例就是this.areaArr[this.areaIndex].id,如果areaIndex==-1就是没选区号。
                areaName: "", // 总区号搜索关键字
                smsForm: {
                    company:"",
                    contact:"",
                    email:"",
                    mobile: "",
                    areaCode: "",
                    obj: 1,
                    msg_code: "",
                    code:"",//用户编码
                },
                // 倒计时周期
                countNum: 60,
                // 用于倒计时标记，true-正在倒计时
                countFlag: false,
                // 定时器
                intervalBtn: {},
            };
        },
        mounted() {
            this.prepareData();
        },
        watch: {
            areaName(val) {
                this.areaListHandel(val);
            }
        },
        methods: {
            prepareData() {
                // 获取客户服务信息
                this.$http.get("/v1/getBindPrepare").then((res) => {
                    if (res.code == 200) {
                        this.areaArr = res.data.countrys;
                        this.smsForm.code = res.data.session_key;
                        this.smsForm.company = res.data.user.company.username;
                        this.smsForm.contact = res.data.user.realname;
                        this.smsForm.email = res.data.user.email;
                        this.smsForm.mobile = res.data.user.mobile;
                        this.smsForm.areaCode = res.data.user.area_code;
                        this.areaName = res.data.user.area_code;
                        this.typeSel = res.data.user.typeSel;
                        this.taglibList = res.data.tagsArr.tagList;
                        this.tagSel = res.data.tagsArr.tag_ids;
                    }
                });
            },
            bindInfo() {
                //短信注册
                this.$http.post("/v1/saveBase", {
                    code: this.smsForm.code,
                    mobile: this.smsForm.mobile,
                    area_code: this.smsForm.areaCode,
                    obj: this.smsForm.obj,
                    msg_code: this.smsForm.msg_code,
                    company: this.smsForm.company,
                    contact: this.smsForm.contact,
                    type: this.typeSel,
                    email: this.smsForm.email,
                    tag: this.tagSel,
                }).then((res) => {
                    // console.log(res);
                    Toast(res.msg);
                    if (res.code == 200) {
                        this.$router.push("/mine");
                    }
                });
            },
            areaListHandel(val) {
                //通过关键字筛选国家区号列表
                if (val) {
                    this.areaList = this.areaArr.filter(
                        (item) => item.name.indexOf(val) != -1
                    );
                } else {
                    this.areaList = this.areaArr;
                }
            },
            showArea() {
                //显示区号框
                this.areaListHandel(this.areaName);
                this.areaShow = true;
            },
            hideArea() {
                // 隐藏区号框
                setTimeout(() => {
                    this.areaShow = false;
                }, 0);
            },
            clearPicker() {
                // 清除国家区号选择
                this.areaIndex = -1;
                this.areaName = "";
            },
            selectPicker(index) {
                // 选择国家区号下拉项
                this.areaName = this.areaList[index].name;
                this.smsForm.areaCode = this.areaList[index].area_code;
                this.areaIndex = index;
                this.areaShow = false;
            },
            sendMsg() {
                if (!this.countFlag) {
                    return;
                }
                let that = this;
                //发送短信
                this.$http.post("/v1/sendSmsCode", {
                    mobile: this.smsForm.mobile,
                    areaCode: this.smsForm.areaCode,
                    obj: this.smsForm.obj,
                }).then((res) => {
                    Toast(res.msg);
                    if (res.code == 200) {
                        that.countDown();
                    }
                });
            },
            countDown() {
                // 设置btn倒计时时显示的信息
                // 更改btn状态
                this.countFlag = !this.countFlag;
                // 设置倒计时
                this.intervalBtn = setInterval(() => {
                    if (this.countNum <= 0) {
                        // 重置btn提示信息
                        // 清除定时器
                        clearInterval(this.intervalBtn)
                        // 更改btn状态
                        this.countFlag = !this.countFlag;
                        // 重置倒计时状态
                        this.countNum = 5;
                    }
                    // 倒计时
                    this.countNum--;
                }, 1000);
            },
        },
    };
</script>
<style scoped>
    .login-main {
        padding-bottom: 50px;
        background: url(../assets/img/head.png);
        background-size: 100% 20%;
        /* background-size: contain; */
        background-repeat: no-repeat;
        min-height: calc(100vh - 50px);
        display: flex;
        flex-direction: column;
    }

    .login-head {
        padding: 0 45px;
        height: 20vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .login-head-title {
        font-size: 20px;
        font-weight: bold;
        color: white;
        margin-bottom: 5px;
    }

    .login-head-brief {
        color: white;
        font-size: 15px;
        font-weight: bold;
    }

    .login-body {
        margin-top: 5vw;
        width: 100vw;
    }

    .login-right {
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /* box-shadow: 0 0 15px 0 #ccc; */
    }

    .van-cell {
        box-sizing: border-box;
    }

    .login-btn {
        font-size: 16px;
        background: #5d74f2;
        width: 40vw;
        border: none;
        height: 45px;
        margin: 15vw auto 0;
        box-shadow: 0 0 10px 0 #5d74f2;
    }

    .login-input {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        height: 50px;
        background: transparent;
    }

    .login-code-btn {
        font-size: 16px;
        background: #5d74f2;
        border: none;
        margin: auto;
        height: 30px;
        color: white;
        font-size: 14px;
    }

    .login-icon {
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }

    .to-other-page {
        padding-right: 16px;
        text-align: right;
        font-size: 12px;
        color: #5d74f2;
    }

    .picker-main {
        position: absolute;
        top: 33px;
        right: 0;
        width: 100%;
        border: 1px solid #ccc;
        z-index: 1;
        background: white;
        border-radius: 3px;
        max-height: 300px;
        overflow: auto;
    }

    .picker-list {
        text-align: left;
        padding: 3px 10px;
        border-bottom: 1px solid #e1e1e1;
    }

    .van-cell::after {
        border-bottom: 1px solid #dedcf1;
        transform: scaleY(1);
    }

    .van-cell {
        width: 50%;
        overflow: initial !important;
    }

    .van-tabs {
        padding: 0 20px;
        margin-bottom: 10px;
    }

    .taglibs {
        align-content: center;
        flex-direction: column;
    }

    .taglibs-item {
        padding: 5px 4px;
    }
</style>
